@mixin rwd($w) {
  @media only screen and (max-width: $w) {
    @content; } }
@mixin rwd2($w:751px) {
  @media only screen and (min-width: $w) {
    @content; } }
@mixin rwd3($h:900px) {
  @media only screen and (max-height: $h) {
    @content; } }
@mixin rwd4($h:900px,$w:1100px) {
  @media only screen and (max-height: $h) and (max-width: $w) {
    @content; } }
@mixin rwd5($h:900px,$w:900px) {
  @media only screen and (max-height: $h) and (max-width: $w) {
    @content; } }

@mixin minW($w) {
  @media only screen and (min-width: $w) {
    @content; } }
@mixin rwdh($h:900px) {
  @media only screen and (max-height: $h) {
    @content; } }
@mixin minH($h:900px) {
  @media only screen and (min-height: $h) {
    @content; } }

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both; } }

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }

@mixin animation($content) {
  -webkit-animation: $content;
  -moz-animation: $content;
  -o-animation: $content;
  animation: $content; }

@mixin before($txt:'') {
  &:before {
    content: $txt;
    display: block;
    @content; } }

@mixin after($txt:'') {
  &:after {
    content: $txt;
    display: block;
    @content; } }

@mixin hover($w:768px) {
  @media only screen and (min-width: $w) {
    &:hover {
      @content; } } }

@mixin pstc0($t:0,$l:0,$b:0,$r:0) {
  position: absolute;
  top: $t;
  bottom: $b;
  left: $l;
  right: $r;
  margin: auto; }

@mixin pstc5($t:50%,$l:50%) {
  position: absolute;
  top: $t;
  left: $l;
  transform: translate(-50%,-50%); }

@mixin dib {
  display: inline-block;
  vertical-align: middle; }

@function px-to-percent($width) {
  @return ($width / 750) * 100%; }

@function px-to-rem($px) {
  @return ($px / 24) * 1rem; }
