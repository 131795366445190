@import "./_base";
@import "./_variable";

// @font-face
// 	font-family: 'acumin'
// 	src: url("../fonts/Acumin Pro Medium.otf") format("opentype")
// @font-face
// 	font-family: 'acumin-light'
// 	src: url("../fonts/Acumin Pro Light.otf") format("opentype")
// @font-face
// 	font-family: 'acumin-regular'
// 	src: url("../fonts/Acumin Pro Book.otf") format("opentype")


* {
	padding: 0;
	margin: 0;
	font-family: acumin,'Noto Sans TC', sans-serif;
	font-weight: normal;
	vertical-align: baseline;
 }	// outline: 1px solid #f00

img {
	width: 100%;
	vertical-align: top; }

a {
	text-decoration: none; }

body,html {}

html {
	font-size: 12px;
	@include rwd(375px) {
		font-size: 12px; } }

body.hide {
	width: 100%;
	height: 100vh;
	overflow: hidden; }

header {
	width: 100%;
	padding: 2.5rem;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	transition: .3s;
	.logo {
		width: 446px;
		padding-top: 4px;
		display: none;
		span.d {
			display: block; }
		span.m {
			display: none; } }
	.menu-box {
		display: none;
		padding-bottom: 4px;
		.mobile-menu {
			display: none; }
		.menu {
			.wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.item {
					padding: 0rem 1.5rem;
					color: $black-001;
					border-left: 2px solid $black-001;
					p {
						font-size: 2rem;
						position: relative;
						font-weight: 300;
						@include before {
							width: 100%;
							height: 2px;
							background-color: $black-001;
							opacity: 0;
							position: absolute;
							left: 0;
							bottom: 0; } }
					.svg-box {
						width: 20px;
						padding: 2.5px;
						fill: $black-001;
						stroke: $black-001; }
					&:nth-child(1) {
						border-left: none; }
					@include hover {
						p {
							&:before {
								opacity: 1; } } } } } } }

	&.dark {
		background-color: $black-001;
		.logo {
			fill: $white-001; }
		.menu {
			.wrapper {
				.item {
					color: $white-001;
					border-left: 2px solid $white-001;
					p {
						@include before {
							width: 100%;
							height: 2px;
							background-color: $white-001;
							opacity: 0;
							position: absolute;
							left: 0;
							bottom: 0; } }
					.svg-box {
						fill: $white-001;
						stroke: $white-001; } } } } }
	&.fix {
		position: fixed; }
	@include rwd(768px) {
		padding: 0;
		padding-left: 2.5rem;
		.logo {
			width: 52.6%;
			span.d {
				display: none; }
			span.m {
				display: block; } }
		.menu-box {
			.menu {
				display: none;
				width: 100%;
				height: 100%;
				background-color: $black-001;
				position: fixed;
				top: 0;
				left: 0;
				.wrapper {
					width: 100%;
					height: 100%;
					flex-wrap: wrap;
					justify-content: center;
					align-content: center;
					text-align: center;
					.item {
						width: 100%;
						padding: 8vw 0;
						color: $white-001;
						border-left: none;
						p {
							display: inline-block;
							font-size: 9.3vw;
							position: relative; }
						.svg-box {
							width: 10.2vw;
							margin: auto;
							padding: 2.5px;
							fill: $white-001;
							stroke: $white-001; }
						&.active {
							color: $yellow-001;
							p {
								@include before {
									width: 100%;
									height: 2px;
									background-color: $yellow-001;
									position: absolute;
									left: 0;
									bottom: 0; } }
							.svg-box {
								fill: $yellow-001;
								stroke: $yellow-001; } } } }
				.close {
					width: 9vw;
					height: 9vw;
					position: absolute;
					top: 2vw;
					right: 2vw;
					transform: rotate(45deg);
					cursor: pointer;
					@include before {
						width: 0.5vw;
						height: 100%;
						background-color: $white-001;
						@include pstc0; }
					@include after {
						width: 100%;
						height: 0.5vw;
						background-color: $white-001;
						@include pstc0; } } }
			.mobile-menu {
				display: block;
				width: 60px;
				stroke: $black-001; } }
		&.dark {
			.menu-box {
				.mobile-menu {
					stroke: $white-001; } } } } }

footer {
	padding-top: 50px;
	background-color: $yellow-001;
	position: relative;
	figure.logo {
		max-width: 996px;
		width: 55vw;
		position: absolute;
		left: 0;
		bottom: 0; }
	.wrapper {
		padding: 2.5rem 5.333333rem;
		text-align: right;
		.box {
			width: 220px;
			text-align: left;
			display: inline-block;
			vertical-align: top;
			.title {
				padding-bottom: 0.5rem;
				margin-bottom: 0.5rem;
				font-size: 2rem;
				color: $black-001;
				border-bottom: 1.5px solid $black-001;
				font-weight: 300; }
			a.link {
				padding-top: 0.5rem;
				display: block;
				font-size: 2rem;
				color: $black-001;
				font-weight: 300;
				& + .title {
					margin-top: 4rem; }
				&.email {
					word-break: break-word; } } }
		.copyright {
			margin-top: 4rem;
			font-size: 1.5rem; } }
	@include rwd(768px) {
		padding-top: 2rem;
		figure.logo {
			width: 83.6%;
			position: relative; }
		.wrapper {
			padding: 6rem 2.5rem;
			text-align: left;
			font-size: 0;
			.box {
				width: 50%;
				text-align: left;
				display: inline-block;
				vertical-align: top;
				.title {
					font-size: 1.2rem; }
				a.link {
					padding-top: 0.5rem;
					display: block;
					font-size: 1.2rem;
					& + .title {
						margin-top: 4rem; } } }
			.copyright {
				margin-top: 0;
				font-size: 1rem;
				position: absolute;
				top: 4.5rem; } } } }


.btn-animate {
	overflow: hidden;
	span {
		position: absolute;
		width: 100%;
		height: 200%;
		background-color: $yellow-002;
		transform: translateY(100%);
		border-radius: 50%;
		left: 0%;
		top: 100%;
		z-index: -1; }
	&.mouse-in {
		span {
			transition: 2s;
			transform: translateY(-50%) scale(2); } }
	&.mouse-out {
		span {
			transition: 2s;
			transform: translateY(-250%) scale(2); } } }

.text-animation-wrapper {
	overflow: hidden;
	.text-animation {
		display: inline-block; } }
